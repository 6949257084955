.disqus-link{
    display: inline-block;
    margin-left: 8px;
    padding-left: 12px;
    border-left: #d5dbde 1px solid;
    font-size: 1.3rem;
    white-space: nowrap;
}
.read-more {
	font-size: 1.3rem;
}

.page-title, .page-description {
    a{
        text-decoration: none;
    }
}

.post-footer .share{
    width: auto;
}